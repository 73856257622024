import React from 'react'
import { Link } from 'react-router-dom'

const FooterComponent = () => {
  return (
    <>
 <footer className="footer-wrapper">
   <div className="container">
     {/* <div className="row">
       <div className="search-wrapper">
         <div className="col-sm-6">
           <div className="search-inner-text">
             <h3>Want to know about ___ offers first?  <span>Subscribe our newsletter </span></h3>
           </div>
         </div>
         <div className="col-sm-6">
           <div className="footer-search-form">
             <div className="form">
               <input placeholder="Your Email Address" id="exampleInputName" className="form-control first" type="text" />
               <input className="bttn" value="Subscribe" type="text" />
             </div>
           </div>
         </div>
       </div>
     </div> */}
     <br />
     <div className="row">
       <div className="col-md-3 col-sm-6">
         <div className="intro">
           <div className="flogo">
            <img src="images/logo_footer.png" alt="" width={260} height={45}/>
           </div>
           <p className="intro-content">
            PT Telesindo Citra Sejahtera memulai bisnis sebagai distributor untuk Schneider Electric sejak tahun 1996. 
Kami hadir sebagai game-changer dalam industri elektrikal dan mekanikal. 
<br /><br />Visi kami adalah untuk memperkenalkan dan melayani berbagai macam produk listrik profesional berkualitas tinggi untuk bangunan dan industri. 
<br /><br />Prinsip dan pelanggan adalah mitra kami dalam berbisnis. 
Kami hadir sebagai sebuah tim untuk menjadi solusi terbaik bagi kepentingan semua. </p>
         </div>
       </div>
       <div className="col-md-3 col-sm-6">
         <div className="extralinks">
           <h4 className="title">Sitemap </h4>
           <div className="extralinks-cols">
             <ul className="extralinks-col">
               <li> <Link to="/referencee">REFERENSI KAMI</Link>  </li>
               <li> <Link to="/about">TENTANG KAMI</Link>  </li>
               <li> <Link to="/product">PRODUK</Link>  </li>
               <li> <Link to="/reward">PENGHARGAAN</Link>  </li>
             </ul>
           </div>
         </div>
       </div>
       <div className="col-md-2 col-sm-6">
         <div className="extralinks">
           <h4 className="title">&nbsp; </h4>
           <div className="extralinks-cols">
             <ul className="extralinks-col">
               <li><Link to="/news">BERITA</Link>  </li>
               <li><Link to="/gallery">GALERI</Link>  </li>
               <li><Link to="/contact">HUBUNGI KAMI</Link> </li>
             </ul>
           </div>
         </div>
       </div>
       <div className="col-md-4 col-sm-6">
         <div className="address">
           <h4 className="title">HUBUNGI KAMI </h4>
           <div className="extralinks-cols">
             <ul className="extralinks-col">
               <li>  <span className="fcontact-icon fa fa-clock-o"></span> Senin - Jumat 8:30 - 17:00  <br />
                &emsp;&emsp;Sabtu 08:30 - 14:00  </li>
               <li><span className="fcontact-icon fa fa-map-marker"></span> Komplek Ruko Glodok Plaza Blok F, No.106-107,<br /> 
               &emsp;&emsp;Jl. Pinangsia Raya, Jakarta Barat 11180 </li>
               <li>
                <span className="fcontact-icon fa fa-phone"></span>  021-600 5000<br />
                &emsp;&emsp;021-600 6000
                </li>
                <li>
                <Link to='https://api.whatsapp.com/send?phone=6281511188850' target='_blank'><span className="fcontact-icon fa fa-whatsapp"></span>+62 815 111 888 50</Link>
                </li>
                <li>
                <Link to='mailto:sales@telesindo-pt.co.id' target='_blank'><span className="fcontact-icon fa fa-envelope"></span> sales@telesindo-pt.co.id</Link>
                </li>
                <li>
                 <Link to='https://www.telesindo.id/' target='_blank'><span className="fcontact-icon fa fa-shopping-cart"></span>telesindo.id</Link>          
                </li>
             </ul>
           </div>
         </div>
       </div>
     </div>
   </div>
 </footer>
 {/* <!-- Footer Wrapper End -->  */}
 {/* <!-- Copyright Wrapper End --> */}
 <div className="copyright-wrapper">
   <div className="container">
     <div className="row">
       <div className="col-sm-7">
         <p>&copy;<span id="year"></span>, PT Telesindo Citra Sejahtera </p>
       </div>
       <div className="col-sm-5">
         <ul className="footer-social-icons">
           <li className="facebook"><a href="https://www.facebook.com/TelesindoOne.ID/" target='_blank'><i className="fa fa-facebook"></i></a></li>
           <li className="instagram"><a href="https://www.instagram.com/telesindo_one.id/" target='_blank'><i className="fa fa-instagram"></i></a></li>
           <li className="youtube"><a href="https://www.youtube.com/@telesindo_one" target='_blank'><i className="fa fa-youtube"></i></a></li>
           <li className="linkedin"><a href="https://www.linkedin.com/company/telesindo-citra-sejahtera/" target='_blank'><i className="fa fa-linkedin"></i></a></li>           
           <li className="dribbble"><a href="https://www.telesindo.id/" target='_blank'><i className="fa fa-dribbble"></i></a></li>
         </ul>
       </div>
     </div>
   </div>
 </div>
    </>
  )
}

export default FooterComponent