import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from "react-ga4";
ReactGA.initialize("G-3FWC908LGE");

ReactGA.send({ 
  hitType: "pageview", 
  page: window.location.pathname, 
  title: "telesindo-one.id" 
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

